.iot-display {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.iot-display-basic {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 3rem;
  //background-color: #f5f5f5;
  font-family: "Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
  font-size: medium;
}

.iot-display-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.iot-display-value-column {
  display: flex;
  margin-left: auto;
}

.controls-area {
  max-height: 8rem;
}